<template>
  <div v-if="hasPerm('youth.view_seanceinscription')" ref="pdfAndXls">
    <page-header title="Vérification du pointage" icon="fas fa-clock"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row>
      <b-col>
        <div v-if="items.length === 0 && !isLoading(loadingName)" class="empty-text">
          Tous les pointages ont été effectués
        </div>
        <div v-if="items.length && !isLoading(loadingName)">
          <x-table
            id="pointage"
            :columns="getColumns()"
            :items="items"
          >
          </x-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import XTable from '@/components/Controls/Table/Table.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { BackendApi } from '@/utils/http'

export default {
  name: 'youth-homes',
  mixins: [BackendMixin],
  components: {
    XTable,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'checking-clocking',
      rawItems: [],
    }
  },
  watch: {
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    items() {
      return this.rawItems.map(this.makeItem)
    },
  },
  mounted() {
    this.loadChecking()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      return [
        {
          name: 'date',
          label: 'Date',
          dateFormat: 'dddd LL',
        },
        {
          name: 'youthHome',
          label: 'Accueil de loisirs',
        },
        {
          name: 'seanceType',
          label: 'Type',
        },
        {
          name: 'period',
          label: 'Période',
        },
        {
          name: 'count',
          label: 'à pointer',
          isLink: true,
          number: true,
          alignRight: true,
          btn: 'secondary',
          onClick: item => {
            router.push(
              this.getLinkToInscriptions(item.raw)
            )
          },
          linkUrl: item => {
            return router.resolve(
              this.getLinkToInscriptions(item.raw)
            ).href
          },
        }
      ]
    },
    makeItem(rawItem) {
      return {
        id: rawItem.key,
        key: rawItem.key,
        date: rawItem.date,
        youthHome: rawItem.youth_home.name,
        seanceType: rawItem.seance_type.name,
        period: rawItem.period.name,
        count: rawItem.count,
        raw: rawItem,
      }
    },
    async loadChecking() {
      this.startLoading(this.loadingName)
      const url = '/api/clocking-checking/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.rawItems = resp.data
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getLinkToInscriptions(item) {
      let data = {
        name: 'youth-homes-day',
        params: {
          day: dateToString(item.date, 'YYYY-MM-DD'),
          youthHome: '' + item.youth_home.id,
          seanceType: '' + item.seance_type.id,
          seancePeriod: '' + item.period.id,
        },
      }
      return data
    },
  },
}
</script>

<style lang="less">

</style>
